import React, { useEffect, useState } from "react";
import './ScoreEntryPageOptionOne.css';

interface IScoreEntryPageOptionOne {
    onNextSaveClick : Function,
    onSaveClick : Function,
    selectedEntryItem :any,
    isLastItem :boolean,
    onCancelClick:any
}

const ScoreEntryPageOptionOne = (props :any) => { 
    const [scoreData, setScoreData] = useState<any>({});

    useEffect(() => {
        setScoreData(props.selectedEntryItem);
    }, [props.selectedEntryItem])

    const updateDetails = (type :any, value:any) =>{
        setScoreData({
            ...scoreData,
            score:{
                ...scoreData.score,
                [type] : value
            }
           });
    }

    return(
        <>
        <div className="card" key={scoreData?.id}>
        <div className="container">
            <div className="row align-items-center mb-3">
              <div className="col-auto">
                <input type="number" onChange={(e:any) => updateDetails("score", e.target.value)} value={scoreData?.score?.score} className="form-control width100"/>
              </div>
              <div className="col-auto">
                <label className="text-nowrap">JN {scoreData?.judging_number}</label>
              </div>
              <div className="col-auto">
                <label className="text-nowrap">Table No: {scoreData?.table_position}</label>
              </div>
            </div>
        
        <div className="notes-section">
            <h4>Notes</h4>
            <textarea value={scoreData?.score?.notes} onChange={(e:any) => updateDetails("notes", e.target.value)} placeholder="Enter your notes here..."></textarea>
        </div>
        <div className="button-group text-center">
            <button className="btn btn-group-sm" onClick={() => props.onSaveClick(scoreData)}>Save</button>
            <button id="btnSaveAndNext" disabled={props.isLastItem} className="btn btn-group-sm" onClick={() => props.onNextSaveClick(scoreData)}>Save & Next</button>
            <button className="btn btn-group-sm" onClick={() => props.onCancelClick()}>Cancel</button>          
        </div>
  
    </div>
        </div>
        </>

    ) 
}

export default ScoreEntryPageOptionOne;