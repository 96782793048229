import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './_components/Login/Login'
import SelectEventDay from './_components/Event/SelectEventDay'
import RegisterNewUser from './_components/Register/RegisterNewUser';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'font-awesome/css/font-awesome.min.css';
import ForgotPassword from './_components/ResetPassword/ForgotPassword';
import ResetPassword from './_components/ResetPassword/ResetPassword';
import ResetPasswordConfirmation from './_components/ResetPassword/ResetPasswordConfirmation';

function App() {
  return (
    <>
	    	<Routes>
	    		<Route path="/" element={<Login />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/RegisterNewUser" element={<RegisterNewUser />} />
	    		<Route path="/SelectEventDay" element={<SelectEventDay />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/ResetPasswordConfirmation" element={<ResetPasswordConfirmation />} />
	    	</Routes>
        <ToastContainer position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable 
          pauseOnHover
          theme="colored"/>
    </>
  );
}

export default App;