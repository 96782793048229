import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { authenticationService } from '../../_services/authentication.service';
import { toast } from 'react-toastify';
import Loader from "../Common/Loader";

const Login = () => { 
   useEffect(()=> {
   // redirect to select Event Day if already logged in
   if (authenticationService.currentUserValue) { 
       navigate('/selectEventDay');
   }
   });

    const navigate = useNavigate();
    const [username, setUsername] = useState<any>('');
    const [password, setPassword] = useState<any>('');
    const [usernameError, setUsernameError] = useState<any>('');
    const [passwordError, setPasswordError] = useState<any>('');
    const [status, setStatusError] = useState<any>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const onSignInButtonClick = async () => {
        // Set initial error values to empty
        setUsernameError('');
        setPasswordError('');
        setStatusError('');
         if(validate())
         {
             setIsLoading(true);
             await authenticationService.login(username, password)
             .then((response :any) => {
                 setIsLoading(false);
                navigate('/selectEventDay');
            },
            error => {
                 setIsLoading(false);
                 toast.error(error);
                 return;
             });
          }
    }

    const validate = () => {
        let isValid = true;
        if (!username) {
            isValid = false;
            setUsernameError("Please enter your username.");
          }
          if (typeof username !== "undefined") {
            const re = /^\S*$/;
            if (username.length < 6 || !re.test(username)) {
              isValid = false;
              setUsernameError("Please enter valid username.");
            }
          }

          if (!password) {
            isValid = false;
            setPasswordError("Please enter your password.");
          }
          if (typeof password !== "undefined") {
            if (password.length < 8) {
              isValid = false;
              setPasswordError("Please add at least 8 character.");
            }
          }

          return isValid;
    }

    const onRegisterNewUserClick = () => {
         navigate('/RegisterNewUser');
    }

    const onForgotPasswordClick = () => {
        navigate('/ForgotPassword');
   }

    return(
        <div className="login-form container-custom">
            <h4>Login</h4>
            <form>
               {isLoading && <div className="center"><Loader/></div> }
                {status &&
                     <div className="mb-3">
                        <label className="errorLabel">{status}</label>
                    </div>
                }
                <div className="mb-3">
                    <label className="form-label">Username</label>
                    <input type="text" value={username} className="form-control" id="username" placeholder="Username" onChange={(e:any) =>  setUsername(e.target.value)}  required/>
                    {usernameError && <label className="errorLabel">{usernameError}</label>}
                </div>
                <div className="mb-3">
                    <label className="form-label">Password</label>
                    <input type="password" value={password} className="form-control" id="password" placeholder="Password" onChange={(e:any) =>  setPassword(e.target.value)} required/>
                    {passwordError && <label className="errorLabel">{passwordError}</label>}
                </div>
                <div className="mb-3 form-check">
                    <input type="checkbox" className="form-check-input" id="rememberMe"/>
                    <label className="form-check-label">Remember Me</label>
                </div>
                <div className="d-flex">
                    <button type="button" className="btn btn-primary me-2" onClick={onSignInButtonClick}>Sign in</button>
                </div>
            </form>
            <div className="footer-link">
                {/* <a href="#" onClick={onForgotPasswordClick}>Forgot Password?</a><br/> */}
                <a href="#" onClick={onRegisterNewUserClick}>Register New User</a>
            </div>
        </div>
) }

export default Login;