import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { eventService } from "../../_services/event.service";
import { toast } from 'react-toastify';
import { error } from "console";
import Loader from "../Common/Loader";

const ResetPassword = () => { 

    const navigate = useNavigate();
    const [token, setToken] = useState<any>('');
    const [password, setPassword] = useState<any>('');
    const [confirmPassword, setConfirmPassword] = useState<any>('');
    const [tokenError, setTokenError] = useState<any>('');
    const [passwordError, setPasswordError] = useState<any>('');
    const [confirmPasswordError, setConfirmPasswordError] = useState<any>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onResetPasswordClick = async () => {
        setTokenError('');
        setPasswordError('');
        setConfirmPasswordError('');

        if(validate())
         {
              setIsLoading(true);
              eventService.resetPassword(token, password, confirmPassword)
              .then((response :any) =>{
                  setIsLoading(false);
                  if(response.message  == ""){
                    debugger;
                      toast.success(response.message);
                      onBackToResetPasswordConfirmation();
                  }
                  else{
                      toast.error(response.message);
                  }
              }).catch((error : any) => { 
                  setIsLoading(false);
                  toast.error(error);
                  //onBackToResetPasswordConfirmation();
              });
          }
    }

    const validate = () => {
        let isValid = true;
          if (!token) {
            isValid = false;
            setTokenError("Please enter your token.");
          }

          if (!password) {
            isValid = false;
            setPasswordError("Please enter your new password.");
          }

          if (!confirmPassword) {
            isValid = false;
            setConfirmPasswordError("Please enter your confirm new password.");
          }

          if (typeof password !== "undefined" && password.length > 0) {
            if (password.length < 8) {
              isValid = false;
              setPasswordError("Please add at least 8 character.");
            }
          }

          if (
            typeof password !== "undefined" &&
            typeof confirmPassword !== "undefined" && password.length > 0 && confirmPassword.length > 0
          ) {
            if (password != confirmPassword) {
              isValid = false;
              setPasswordError("Confirm new passowrd and new password don't match.");
            }
          }
          return isValid;
    }

    const onBackToLoginClick = () => {
         navigate('/Login');
    }

    const onBackToResetPasswordConfirmation = () => {
      navigate('/ResetPasswordConfirmation');
 }

    return(
        <div className="login-form container-custom">
            <h4>Reset account password</h4>
            <div>
                <p>Please enter a new password below.</p>
            </div>
            <form>
                {isLoading && <div className="center"><Loader/></div> }
                { <div className="mb-3">
                    <label className="form-label">Token</label>
                    <input type="text" value={token} className="form-control" id="token" placeholder="Token" onChange={(e:any) =>  setToken(e.target.value)}  required/>
                    {tokenError && <label className="errorLabel">{tokenError}</label>}
                </div> }
                <div className="mb-3">
                    <label className="form-label">New Password</label>
                    <input type="password" value={password} className="form-control" id="password" placeholder="New Password" onChange={(e:any) =>  setPassword(e.target.value)} required/>
                    {passwordError && <label className="errorLabel">{passwordError}</label>}
                </div>
                <div className="mb-3">
                    <label className="form-label">Confirm New Password</label>
                    <input type="password" value={confirmPassword} className="form-control" id="confirmpassword" placeholder="Confirm New Password" onChange={(e:any) =>  setConfirmPassword(e.target.value)} required/>
                    {confirmPasswordError && <label className="errorLabel">{confirmPasswordError}</label>}
                </div>
                <div className="d-flex">
                    <button type="button" className="btn btn-primary me-2" onClick={onResetPasswordClick}>Reset Password</button>
                    <button type="button" className="btn btn-primary me-2" onClick={onBackToLoginClick}>Back to Login</button>
                </div>
            </form>
        </div>
) }

export default ResetPassword;