import { BehaviorSubject } from 'rxjs';

import config from '../config';
import { handleResponse } from '../_helpers/handle-response';

const currentUserSubject = new BehaviorSubject(localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')??"") : null);

async function login(username:any, password:any) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ username : username, password : password})
    };
    return await fetch(`${config.apiUrl}/api/v1/token/pair`, requestOptions)
        .then(response => response.json())
        .then(user => {
            if(user != null)
            {
                if(user.username != null && user.refresh != null && user.access != null)
                {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    currentUserSubject.next(user);
                    return user;
                }
                else
                {
                    if(user.detail != null)
                    {
                        return Promise.reject(user.detail);
                    }
                    else
                    {
                        return Promise.reject( "Some error occurs during processing. Please try again.");
                    }
                }
            }
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    window.location.href = "/";
}

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};