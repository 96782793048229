import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { eventService } from "../../_services/event.service";
import { toast } from 'react-toastify';
import { error } from "console";
import Loader from "../Common/Loader";
import { colors } from "@mui/material";

const ForgotPassword = () => { 

    const navigate = useNavigate();
    const [email, setEmail] = useState<any>('');
    const [emailError, setEmailError] = useState<any>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isEmailSend,setIsEmailSend] = useState<boolean>(false);

    const onResetPasswordClick = () => {
        setEmailError('');
        setIsEmailSend(false);

      if(validate())
       {
            setIsLoading(true);
            eventService.resetUserPassword(email)
            .then((response :any) =>{
                setIsLoading(false);
                if(response.message == "Password reset."){
                    toast.success(response.message);
                    setIsEmailSend(true);
                    setEmail('');
                    setTimeout(() => {
                        setIsEmailSend(false);
                        onNavigateToResetPassword();
                    }, 7000);
                }
                else{
                    toast.error(response.message);
                    setIsEmailSend(false);
                }
            }).catch((error : any) => { 
                setIsLoading(false);
                toast.error(error) 
            });
        }
    }

    const validate = () => {
        let isValid = true;

          if (!email) {
            isValid = false;
            setEmailError("Please enter your email address.");
          }

          if (typeof email !== "undefined" && email.length > 0) {
            const re = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
            if (!re.test(email)) {
              isValid = false;
              setEmailError("Please enter valid email address.");
            }
          }
          return isValid;
    }

    const onBackToLoginClick = () => {
         navigate('/ResetPassword');
    }

    const onNavigateToResetPassword = () => {
        navigate('/ResetPassword');
   }

    return(
        <div className="login-form container-custom">
            <h4>Reset your password</h4>
            <div>
                <p>If your email is previously registered. we will send you a link with which you can reset your password.</p>
            </div>
            <form>
                {isLoading && <div className="center"><Loader/></div> }
                <div className="mb-3">
                    <label className="form-label">Email Address</label>
                    <input type="text" value={email} className="form-control" id="email" placeholder="Enter email address" onChange={(e:any) =>  setEmail(e.target.value)}  required/>
                    {emailError && <label className="errorLabel">{emailError}</label>}
                </div>

                <div className="d-flex">
                    <button type="button" className="btn btn-primary me-2" onClick={onResetPasswordClick}>Send Password Reset Link</button>
                    <button type="button" className="btn btn-primary me-2" onClick={onBackToLoginClick}>Back to Login</button>
                </div>
            </form>
            {isEmailSend && <div className="footer-link Green-color"><p>Email sent successfully! Please check your inbox for instructions to reset your password.</p></div> }
        </div>
) }

export default ForgotPassword;