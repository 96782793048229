import React, { useEffect, useState } from "react";
import './GroupScoreSummary.css';
import { useNavigate } from 'react-router-dom'
import { eventService } from '../../_services/event.service';
import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import { Box, colors, IconButton, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IGroupScoreSummary 
{
    eventGroupEntryList : any,
    onEntryRowClick : Function,
    groupId : any
}

type GroupScoreSummary = {
entry : number,
entry_name : string,
id : number,
judging_number : number,
table_position : number,
variatals: []
}

const tableCustomStyles = {
  headCells: {
    style: {
      fontSize: '12px',
      fontWeight: 'bold',
      color: "#0000ff"
    },
  },
}
export { tableCustomStyles };

const GroupScoreSummary = (props :IGroupScoreSummary) => 
{   
  const columns = [
    {
      name: 'Action',
      sortable: false,
      cell:(row:any, index:any) =>{
        return(<span className="cursorPointer" title="Edit"><i className="fa fa-edit fa-lg" onClick={() => props.onEntryRowClick(row, row.index)}></i></span>)
      },
      width:"15%",
    },
    {
      name: 'JN',
      selector: (row:any) => row.judging_number,
      sortable: true,
      width:"20%",
    },
    {
      name: 'Score',
      selector: (row:any) => row.score?.score,
      sortable: true,
      width:"20%",
    },
    {
      name: 'Note',
      selector: (row:any) => row.score?.notes,
      sortable: true,
      cell:(row:any) =>{
        if(row.score?.notes?.length > 0 )
        {
        return( <i className="fa fa-file-text-o fa-lg"></i>)
        }
        else{
          return null;
        }
      },
      width:"20%",
    },
    {
      name: 'Table',
      selector: (row:any) => row.table_position,
      sortable: true,
      width:"20%",
    },
  ];

  return (
		<DataTable
            className="dataTables_NoscrollBody"
			columns={columns}
			data={props.eventGroupEntryList??[]}
      pagination
      customStyles={tableCustomStyles}
      defaultSortFieldId="2"
      paginationRowsPerPageOptions={[10, 20, 50, 100]}
		/>
	);
  
 }

export default GroupScoreSummary;