import React, { useEffect, useState } from "react";
import { authenticationService } from '../../_services/authentication.service';
import { eventService } from '../../_services/event.service';
import { useNavigate } from 'react-router-dom'
import './SelectEventDay.css';
import IEventData from "../../_types/Event";
import GroupScoreSummary from "../Entry/GroupScoreSummary";
import { cursorTo } from "readline";
import ScoreEntryPageOptionOne from "../Entry/ScoreEntryPageOptionOne";
import { toast } from "react-toastify";
import Loader from "../Common/Loader";

const SelectEventDay = () => { 
  const [eventID, setEventID] = useState<number>(0);
  const [eventDayID, setEventDayID] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [eventList, setEvents] = useState<any[]>([]);
  const [eventDayList, setEventDays] = useState<any[]>([]);
  const [eventDayGroupList, setEventDayGroupList] = useState<any[]>([]);
  const [eventGroupEntryList, setEventGroupEntryList] = useState<any[]>([]);
  const [groupID, setGroupID] = useState<number>(0);
  const [judgeId, setJudgeId] = useState<number>(0);
  const [selectedEntryItem, setSelectedEntryItem] = useState<any>();
  const [nextEntryItem, setNextEntryItem] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState<any>();
  const [isLastItem, setIsLastItem] = useState<any>(false);

  useEffect (() => {
    getAllEvents();
  },[])

  useEffect(()=> {
    if (eventID) {
       eventService.getAllEventDay(eventID).then((response :any) => {
        setEventDays(response);
      });
    } else {
      setEventDays([]);
      setEventDayGroupList([]);
      setEventGroupEntryList([]);
    }
  },[eventID]);

  useEffect(()=> {
    if(eventID && eventDayID)
    {
      setIsLoading(true);
      eventService.getGetAllTheGroupsByEventIdAndEventDayId(eventID,eventDayID)
       .then((response : any) => {
          setIsLoading(false);
          setEventDayGroupList(response);
       }).catch((error : any) => { 
        setIsLoading(false);
        toast.error(error); 
    });
    }
    else
    {
      setEventDayGroupList([]);
    } 
  },[eventDayID]);

  useEffect(()=> {
    if(eventID && eventDayID && groupID)
    {
      getGroupEntry();
    }
    else
    {
      setEventGroupEntryList([]);
    } 
  },[groupID]);

const getAllEvents = () => {
    setIsLoading(true);
    eventService.getAllEvents().then((response :any) =>{
      setEvents(response);
      setIsLoading(false);
    }).catch((error :any)=>{
      toast.error(error);
      setIsLoading(false);
    });
};

const getGroupEntry = () => {
  setIsLoading(true);
  eventService.getAllTheEntriesByGroupId(eventID, eventDayID, groupID).then((response :any) =>{
    if(response != undefined && response != null && response.length > 0)
    {
      response = response.sort((a : any, b : any) => (a.judging_number - b.judging_number));
      response = response.map((item : any , index: any)=>({...item , index:index}));
    }
    setEventGroupEntryList(response);
    setIsLoading(false);
  }).catch((error :any)=>{
    toast.error(error);
    setIsLoading(false);
  });
};

const handleEventChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedEventID = Number(e.target.value);
    setEventID(selectedEventID);
    setEventDayID(0); // Reset event day when event is changed
    setGroupID(0);
    setJudgeId(0);
    setSelectedEntryItem(null);
    setIsLastItem(false);
};

const handleEventDayChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setEventDayID(Number(e.target.value));
};

const handleGroupItemClick = (item: any) => {
  setGroupID(Number(item));
};

const navigate = useNavigate();

 const onBackButtonClick = () => {
  if(judgeId > 0)
  {
      setJudgeId(0);
      setSelectedEntryItem(null);
      setIsLastItem(false);
  }
  else if(groupID > 0)
  {
      setGroupID(0);
  }
  else if(eventDayID > 0)
  {
      setEventDayID(0);
  }
  else if(eventID > 0)
  {
      setEventID(0);
  }
 }

 const onLogoutButtonClick = () => {
     authenticationService.logout();
     //navigate('/login');
 }

 const onEntryRowClick = (item :any, index :number) =>
 {
    item.score = {
        ...item.score,
        id : item?.score?.id?? 0,
        score: item?.score?.score?? 0,
        notes:item?.score?.notes?? ""
    };
    setSelectedEntryItem(item);
    setJudgeId(item.id);
    if(eventGroupEntryList?.length > (index + 1))
      {
        setNextEntryItem(eventGroupEntryList[index + 1]);
        setCurrentIndex(index+1);
      }
      else{
        setIsLastItem(true);
      }
 }
 
const onNextSaveClick = async (entryData :any) => {
  setIsLoading(true);
  const scoreModel = {
    score : entryData?.score?.score,
    notes : entryData?.score?.notes
  };

  if(scoreModel != null && scoreModel.score === '')
  {
        toast.error("Please enter score value.");
        setIsLoading(false);
        return;
  }

  var response = await eventService.getAllTheEntriesByGroupId(eventID, eventDayID, groupID);
  var scoreEntry = findEntriesById(response, entryData?.id);

  if(scoreEntry?.score?.id > 0)
  {
    eventService.updateScore(eventID, eventDayID, groupID, entryData?.id, scoreEntry?.score?.id, scoreModel).then((response :any) =>
      {
        onEntryRowClick(nextEntryItem, currentIndex);
        setIsLoading(false);
      }).catch((error :any)=>{
        toast.error(error);
        setIsLoading(false);
      });
  }
  else
  {
    eventService.createScore(eventID, eventDayID, groupID, entryData?.id, scoreModel).then((response :any) =>
    {
      onEntryRowClick(nextEntryItem, currentIndex);
      setIsLoading(false);
    }).catch((error :any)=>{
      toast.error(error);
      setIsLoading(false);
    });
  }
  
}

const findEntriesById= (array:any,id :any) => {
  if(Array.isArray(array))
  {
    return array?.find((element:any) => {
      return element.id === id;
    })
  }
}

 const onSaveClick = async (entryData :any) => {
  setIsLoading(true);
  const scoreModel = {
    score : entryData?.score?.score,
    notes : entryData?.score?.notes
  };

  if(scoreModel != null && scoreModel.score === '')
  {
      toast.error("Please enter score value.");
      setIsLoading(false);
      return;
  }
 
  var response = await eventService.getAllTheEntriesByGroupId(eventID, eventDayID, groupID);
  var scoreEntry = findEntriesById(response,entryData?.id);

  if(scoreEntry?.score?.id > 0)
  {
    eventService.updateScore(eventID, eventDayID, groupID, entryData?.id, scoreEntry?.score?.id, scoreModel).then((response :any) =>
      {
        setIsLoading(false);
        getGroupEntry();
        setCurrentIndex(0);
        setNextEntryItem(null);
        setSelectedEntryItem(null);
        setIsLastItem(false);
        setJudgeId(0);
      }).catch((error :any)=>{
        toast.error(error);
        setIsLoading(false);
      });
  }
  else{
    eventService.createScore(eventID, eventDayID, groupID, entryData?.id, scoreModel).then((response :any) =>
      {
        setIsLoading(false);
        getGroupEntry();
        setCurrentIndex(0);
        setNextEntryItem(null);
        setSelectedEntryItem(null);
        setIsLastItem(false);
        setJudgeId(0);
      }).catch((error :any)=>{
        toast.error(error);
        setIsLoading(false);
      });
    }
 }

 const onCancelClick = () => {
  getGroupEntry();
  setCurrentIndex(0);
  setNextEntryItem(null);
  setSelectedEntryItem(null);
  setIsLastItem(false);
  setJudgeId(0);
 }

return(
        <div className="container-custom">

      {(eventDayID == 0) ?
        <div className="text-center mb-4">
            <h5>Welcome {authenticationService?.currentUserValue?.username}</h5>
            <hr/>
        </div> : 
        <>
       <div className="row">
       <div className="col-md-10 col-sm-10"><b>{eventList?.find((s:any) => s.id == eventID)?.name}</b></div>
       <div className="col-md-2 col-sm-2"><b>{eventDayList?.find((s:any) => s.id == eventDayID)?.name}</b></div>
       </div>
       <div className="row">
       <div className="col-md-6 col-sm-6">{authenticationService?.currentUserValue?.username}</div>
       <div className="col-md-6 col-sm-6 role-bc">{groupID == 0 ? "Your Role" : eventDayGroupList?.find((t:any) => t.id == groupID)?.panel} : Associate Judge </div>
       </div>
       <hr/>
        </>
  }
        { isLoading && <div className="row mt-3 mb-3"><Loader/></div>}
        {(eventDayID == 0 && groupID == 0) && 
        <div className="form-group mb-4">
            <label>
                <span className="circled-number mb-2">1</span>
                <b>Choose an event</b> 
            </label>
            <select
              className="form-select w-100"
              id="eventSelect"
              aria-label="Select an event"
              onChange={handleEventChange}
              value={eventID}>
              <option value="">Select an event</option>
              {eventList.map((event) => (
                <option key={event.id} value={event.id}>
                  {event.name}
                </option>
              ))}
            </select>
        </div>}

         {eventID != 0 && eventDayID == 0 && groupID == 0 && (
         <div className="form-group mb-4">
           <label>
             <span className="circled-number mb-2">2</span> 
              <b>Select an event day</b>
           </label>
           <select
             className="form-select w-100"
             id="daySelect"
             aria-label="Select an event day"
             onChange={handleEventDayChange}
             value={eventDayID}
           >
             <option value="">Select a day</option>
             {eventDayList.map((day) => (
               <option key={day.id} value={day.id}>
                 {day.name}
               </option>
             ))}
           </select>
         </div>
       )}
       {
        eventID != 0 && eventDayID != 0 && groupID == 0 && (
         <div className="form-group mb-4">
                     
                     <label>
                         <span className="circled-number mb-2">3</span> <b>Select a group to score / view</b> 
                     </label>
                     
                     <div className="card-body">
                         <ul className="list-group">

                         {eventDayGroupList.map((item, index) => (
                              <li onClick={()=>handleGroupItemClick(item.id)} className="list-group-item d-flex justify-content-between align-items-center cursorPointer" key={index}>
                                <div>
                                    <b>{item.category_code}{item.category_subcode != "" && item.category_subcode != null && item.category_subcode !="-" ? item.category_subcode :""}</b>
                                    <p className="mb-0 txt-grey">{item.category_name}</p>
                                 </div>
                              </li>
                          ))}

                             {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                 <div>
                                     1a
                                     <p className="mb-0">Merlot and Merlot Predominant Blends</p>
                                 </div>
                                 <span className="badge bg-danger rounded-pill">Locked</span>
                             </li>
                             <li className="list-group-item d-flex justify-content-between align-items-center">
                                 <div>
                                     1a
                                     <p className="mb-0">Pinot Gris - Dry</p>
                                 </div>
                                 <span className="badge bg-success rounded-pill">complete</span>
                             </li>
                             <li className="list-group-item d-flex justify-content-between align-items-center">
                                 <div>
                                     1c
                                     <p className="mb-0">Pinot Gris - Dry to Medium</p>
                                 </div>
                                 <div className="progress-circle" data-value="0.75">                            
                                 </div>
                             </li>
                             <li className="list-group-item d-flex justify-content-between align-items-center">
                                 <div>
                                     2b
                                     <p className="mb-0">Cabernet Sauvignon and Cabernet Predominant</p>
                                 </div>
                                 <div className="progress-circle" data-value="0.10">                            
                                 </div>
                             </li>
                             <li className="list-group-item d-flex justify-content-between align-items-center">
                                 <div>
                                     3
                                     <p className="mb-0">Syrah</p>
                                 </div>
                                 <div className="progress-circle" data-value="0.75">                            
                                 </div>
                             </li> */}
                             
                         </ul>
                     </div>
         </div>
        )}

        {groupID > 0 && judgeId == 0 && 
        <>
             <b className="color-blue">{eventDayGroupList?.find((t:any) => t.id == groupID)?.category_code}{eventDayGroupList?.find((t:any) => t.id == groupID)?.category_subcode != "" && eventDayGroupList?.find((t:any) => t.id == groupID)?.category_subcode != null && eventDayGroupList?.find((t:any) => t.id == groupID)?.category_subcode !="-" ? eventDayGroupList?.find((t:any) => t.id == groupID)?.category_subcode :""} - {eventDayGroupList?.find((t:any) => t.id == groupID)?.category_name}</b>
             <GroupScoreSummary eventGroupEntryList={eventGroupEntryList} onEntryRowClick={onEntryRowClick} groupId={groupID} />
        </> 
        }

        {judgeId > 0 && <ScoreEntryPageOptionOne 
        selectedEntryItem={selectedEntryItem} 
        isLastItem={isLastItem} 
        onNextSaveClick={onNextSaveClick}
        onSaveClick={onSaveClick}
        onCancelClick={onCancelClick}
        />}
        <div className="btn-container">
            <button className="btn btn-outline-secondary" onClick={onBackButtonClick}>Back</button>
            <button className="btn btn-outline-secondary" onClick={onLogoutButtonClick}>Logout</button>
        </div>
    </div>
    
)}

export default SelectEventDay;