import { toast } from 'react-toastify';
import { authenticationService } from '../_services/authentication.service';
import { useNavigate } from 'react-router-dom'

export function handleResponseWOLogout(response : any) {
    if (!response.ok) {
            return response.json().then((result:any) => {
            try{
                return result;
            }
            catch(error :any) 
            {
                toast.error(error ?? "Some error occurs during processing. Please try again.");
            }
            });
    }
    
    return response.json().then((result:any) => {
    try{
        return result;
    }
    catch(error :any) 
    {
        toast.error(error);
    }
    });
}