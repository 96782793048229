import config from '../config';
import { handleResponse } from '../_helpers/handle-response';
import { handleResponseWOLogout } from '../_helpers/handle-response-wo-logout';
import { authHeader, authHeaderPost } from '../_helpers/auth-header';

//Get all the events that this user is apart of.
//api/v1/events
const getAllEvents = () =>{
    const requestOptions : RequestInit = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/api/v1/events`, requestOptions).then(handleResponse);
}

//Get all the event days for a given event.
//api/v1/events/{event_id} 
const getEventById = (event_id : any) =>{
    const requestOptions : RequestInit = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/api/v1/events/${event_id}`, requestOptions).then(handleResponse);
}

//Get all the groups for a given event day.
//api/v1/events/{event_id}/eventdays/{eventday_id}/groups 
const getGetAllTheGroupsByEventIdAndEventDayId = (event_id :any,eventday_id :any) => 
{
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/api/v1/events/${event_id}/eventdays/${eventday_id}/groups`, requestOptions).then(handleResponse);
}

//Get all the entries for a given group.
//api/v1/events/{event_id}/eventdays/{eventday_id}/group/{group_id}/entries 
const getAllTheEntriesByGroupId = (event_id : any,eventday_id :any,group_id:any) =>
{
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/api/v1/events/${event_id}/eventdays/${eventday_id}/group/${group_id}/entries_extra`, requestOptions).then(handleResponse);
}


const getAllEventDay = (event_id : any) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/api/v1/events/${event_id}`, requestOptions).then(handleResponse);
};

const getScoreByEntry = (event_id :any, eventday_id :any, group_id :any, entry_category_id :any) =>
{
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/api/v1/events/${event_id}/eventdays/${eventday_id}/group/${group_id}/entry/${entry_category_id}/score`, requestOptions).then(handleResponseWOLogout).then(result => {
        if(result != null)
        {
            if(result.message != null)
            {
               return result;
            }
            else{
                if(result != null && result.id > 0)
                {
                    return result;
                }
                if(result.detail != null)
                {
                    return Promise.reject(result.detail);
                }
                else
                {
                    return Promise.reject("Some error occurs during processing. Please try again.");
                }
            }
        }
        else
        {
            return Promise.reject("Some error occurs during processing. Please try again.");
        }
    });;
}

function createScore(event_id :any, 
    eventday_id :any, 
    group_id :any, 
    entry_category_id :any, 
    score :any) {
    const requestOptions : RequestInit = {
        method: 'Post',
        headers: authHeaderPost(),
        body: JSON.stringify(score)
    };
    return fetch(
        `${config.apiUrl}/api/v1/events/${event_id}/eventdays/${eventday_id}/group/${group_id}/entry/${entry_category_id}/score_2`, requestOptions
    ).then(handleResponseWOLogout).then(result => {
        if(result != null)
        {
            if(result.message != null)
            {
               return result;
            }
            else{
                if(result.detail != null)
                {
                    return Promise.reject(result.detail);
                    
                    // let errorMessage = "";
                    // result.detail.forEach((item : any) => {
                    //         errorMessage = errorMessage + " " + item.msg;
                    // });

                    // if(errorMessage !== "")
                    // {
                    //     return Promise.reject(errorMessage);
                    // }
                    // else
                    // {
                    //     return Promise.reject(result.detail);
                    // }
                }
                else if(result.id > 0)
                {
                    return result;
                }
                else{
                    return Promise.reject("Some error occurs during processing. Please try again.");
                }
            }
        }});
}

function updateScore(event_id :any, 
    eventday_id :any, 
    group_id :any, 
    entry_category_id :any, 
    score_id :any,
    score :any) {
    const requestOptions : RequestInit = {
        method: 'PUT',
        headers: authHeaderPost(),
        body: JSON.stringify(score)
    };
    return fetch(
        `${config.apiUrl}/api/v1/events/${event_id}/eventdays/${eventday_id}/group/${group_id}/entry/${entry_category_id}/score/${score_id}`, requestOptions
    ).then(handleResponseWOLogout).then(result => {
        if(result != null)
        {
            if(result.message != null)
            {
               return result;
            }
            else{
                if(result.detail != null)
                {
                    return Promise.reject(result.detail);
                    
                    // let errorMessage = "";
                    // result.detail.forEach((item : any) => {
                    //         errorMessage = errorMessage + " " + item.msg;
                    // });

                    // if(errorMessage != "")
                    // {
                    //     return Promise.reject(errorMessage);
                    // }
                    // else
                    // {
                    //     return Promise.reject(result.detail);
                    // }
                }
                else if(result.id > 0)
                {
                    return result;
                }
                else
                {
                    return Promise.reject("Some error occurs during processing. Please try again.");
                }
            }
        }});
}

const registerUser = (username: any, email: any, password:any) => {
       const requestOptions : RequestInit = 
       {
            method: 'Post',
            headers: new Headers({'Content-Type': 'application/json'}),
            body: JSON.stringify({username : username, email:email, password:password})
        };
       return fetch(
        `${config.apiUrl}/api/v1/register`, requestOptions).then(handleResponse).then(result => {
        if(result != null)
        {
            if(result.message != null)
            {
               return result;
            }
            else{
                if(result.detail != null)
                {
                    return Promise.reject(result.detail);
                }
                else{
                    return Promise.reject("Some error occurs during processing. Please try again.");
                }
            }
        }});
}   

const resetUserPassword = (email: any) =>{
    const requestOptions : RequestInit = {
     method: 'Post',
     headers: new Headers({'Content-Type': 'application/json'}),
     body: JSON.stringify({email:email})
 };
    return fetch(
     `${config.apiUrl}/api/v1/password_reset`, requestOptions).then(handleResponse).then(result => {
    if(result != null)
    {
        if(result.message != null)
        {
           return result;
        }
        else{
            if(result.detail != null)
            {
                return Promise.reject(result.detail);
            }
            else{
                return Promise.reject("Some error occurs during processing. Please try again.");
            }
        }
    }});
}

const resetPassword = (token: any, password: any, confirmpassword:any) => {
    
    const requestOptions : RequestInit = {
     method: 'PUT',
     headers: new Headers({'Content-Type': 'application/json'}),
     body: JSON.stringify({password : password, password_check : confirmpassword})
    };
    
    return fetch(
     `${config.apiUrl}/api/v1/password_reset/${token}`, requestOptions).then(handleResponse).then(result => {
    if(result != null)
    {
        if(result.message != null)
        {
           return result;
        }
        else{
            if(result.detail != null)
            {
                return Promise.reject(result.detail);
            }
            else{
                return Promise.reject("Some error occurs during processing. Please try again.");
            }
        }
    }});
}

export const eventService = {
    getAllEvents,
    getEventById,
    getGetAllTheGroupsByEventIdAndEventDayId,
    getAllTheEntriesByGroupId,
    getScoreByEntry,
    createScore,
    updateScore,
    getAllEventDay,
    registerUser,
    resetUserPassword,
    resetPassword    
};